<template>
  <v-card flat width="75%" style="margin: auto" class="communication p-t-36">
    <v-card class="width-70p m-auto  b-r-16 p-t-20 overflow-x-auto" >
      <div v-if="textSettings === undefined">
        <v-card-text class="text-center bold-f-s">
          Text and Telephone Communication
        </v-card-text>
        <v-card flat v-scroll.self="onScroll" class="overflow-y-auto b-r-16" style="height: calc(45vh)">
          <v-card-text class="f-s-14 p-b-0 p-t-0 text-center">CONSENT TO RECEIVE TELEPHONE CALLS AND TEXT MESSAGES
          </v-card-text>
          <v-card-subtitle class="p-t-10 p-b-0"> By electronically signing this Agreement by clicking the “I AGREE”
            button and entering your name below, you consent to receiving calls
            and text messages pertaining to your loan, including but not limited to, payment information, account
            information, due dates, delinquencies, program updates relating to your loan, and collection efforts, at
            any phone number you have provided to us, our assignee(s), or anyone trying to collect the loan.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">How To Unsubscribe: You may withdraw your consent to receive text
            messages by texting “STOP” to the message you receive, calling us at {{portfolio.telephone}} or emailing
            us at {{portfolio.email}}. At our option, we may treat your provision of an invalid mobile phone
            number, or the subsequent malfunction of a previously valid mobile phone number, as a withdrawal of your
            consent to receive calls or text messages. We will not impose any fees upon you to process the
            withdrawal of your consent to receive calls or text messages. Any withdrawal of your consent to receive
            calls or use text messages will be effective only after we have a reasonable period of time to process
            your withdrawal.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">
            In order to access, view, and retain text messages that we make available to you, you must have: (1) a
            text message ­capable mobile phone, (2) an active mobile phone account with a communication service
            provider; and (3) sufficient storage capacity on your mobile phone.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">
            To request additional information, text “HELP” to the message you receive or contact us by telephone
            at {{portfolio.telephone}}.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">
            The services are available from most of the carriers that offer text messaging. Consult your mobile
            service carrier to confirm that they offer text messaging.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">
            There is no service fee for text messages but you are responsible for all charges imposed by your
            communications service provider. Such as fees associated with text messaging. Consult your mobile
            service carrier’s pricing plan to determine the charges for sending and receiving text messages. These
            charges will appear on your phone bill. Message frequency depends on account settings.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">You agree that we may send any text messages related to your loan
            through your
            communication service provider in order to deliver them to you and that your communication service
            provider is acting as your agent in this capacity. You agree to indemnify, defend, and hold us harmless
            from and against all claims, losses, liability, costs, and expenses (including reasonable attorneys’
            fees)
            arising from your provision of a mobile phone number that is not your own or your violation of
            applicable
            federal, state, or local law, or regulation or ordinance relating to text messages. Your obligation
            under
            this paragraph shall survive termination of this Agreement. You agree that text messages are provided
            for
            your convenience only.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">Receipt of each text message may be delayed or impacted by factors
            pertaining to your
            communications service provider. We will not be liable for losses or damages arising from any disclosure
            of account information to third parties, non­-delivery, delayed delivery, misdirected delivery or
            mishandling of, or inaccurate content in, the text messages sent by us. We may modify or terminate our
            text messaging services from time to time, for any reason, with or without notice, and without liability
            to you, any other user or third party.
          </v-card-subtitle>
          <v-card-text class="f-s-14 p-b-0 p-t-20 text-center">CONSENT TO RECEIVE ADVERTISING OR TELEMARKETING TEXT MESSAGES AND
            TELEPHONE CALLS
          </v-card-text>
          <v-card-subtitle class="p-t-10 p-b-0">By signing this section, you consent to our sending you advertising
            and telemarketing text
            messages to the mobile phone number you have provided below. You also consent to our making advertising
            or
            telemarketing calls to you at your mobile phone number using automatic telephone dialing system or an
            artificial or prerecorded voice calls or texts.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">Signing this section will be deemed to be your signature
            acknowledging your consent to
            receive advertising and telemarketing text messages and telephone calls as described above to your
            mobile phone.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">
            You are not required to consent to advertising or telemarketing calls, text messages, prerecorded or
            artificial calls or text messages to obtain credit or other services from us. At any time, you may
            withdraw your consent to receive advertising or telemarketing text messages or marketing calls to the
            mobile number provided by calling us at {{portfolio.telephone}} or emailing us at {{portfolio.email}}.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">You understand that: any advertising and telemarketing text messages
            we send you may be
            accessed by anyone with access to your text messages; and your mobile phone service provider may charge
            you fees for advertising and telemarketing text messages that we send you, and you agree that we shall
            have no liability for the cost.
          </v-card-subtitle>
        </v-card>
        <v-list
          flat
          subheader
          one-line
        >
          <v-list-item>
            <template>
              <v-list-item-action>
                <v-checkbox @change="text" v-model="textSettings"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>I agree</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list>
      </div>
      <div v-if="textSettings !== undefined">
        <v-card-text class="bold-f-s text-center">
          E-Sign and Electronic Communication
        </v-card-text>
        <v-card flat
                v-scroll.self="onScroll" class="overflow-y-auto b-r-16" style="height: calc(45vh)">
          <v-card-text class="f-s-14 text-center p-b-0 p-t-0">CONSENT TO ELECTRONIC COMMUNICATIONS</v-card-text>
          <v-card-subtitle class="p-t-10 p-b-0">
            The following terms and conditions govern electronic communications in connection with this
            Agreement and the transaction evidenced hereby (this “Consent”). By electronically signing this
            Agreement
            you are confirming that you have agreed to the terms and conditions of this Consent and that you have
            the
            ability to download or print a copy of this Consent for your records. You further agree that:
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">Any disclosure, notice, record or other type of information that is
            provided to you in
            connection with your transaction with us, including but not limited to, this Agreement, this Consent,
            disclosures, Privacy notice, change-in-term notices, fee and transaction information, statements,
            delayed
            disbursement letters, notices of adverse action, and transaction information (collectively,
            “Communications”), may be sent to you electronically by sending it to you by e-mail as permitted by
            applicable law.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">We will not be obligated to provide any Communication to you in
            paper
            form unless you
            specifically request us to do so.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">
            You may obtain a copy of any Communication by contacting us at {{ portfolio.website }}, writing to us:
            {{ portfolio.email }}, or by calling us at {{portfolio.telephone}}. You will not be charged a fee for
            such
            copy. You also can withdraw your consent to ongoing electronic communications in the same manner and ask
            that they be sent to you in paper or non-electronic form. If you choose to receive Communications in
            paper
            or non-electronic form, we may elect to terminate this Agreement and demand payment of the amount then
            due
            by the date of your withdrawal of consent; or by the expiration of any minimum term mandated by law,
            whichever is later.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">
            You agree to provide us with your current e-mail address for notices at the address or phone number
            indicated above. If your e-mail address changes, you must send us a notice of the new address by writing
            to us or sending us an e-mail, using secure messaging, at least five(5) days before the change.
          </v-card-subtitle>
          <v-card-subtitle class="p-t-10 p-b-0">
            In order to receive electronic communications in connection with this transaction, you will
            need a working connection to the Internet. Your browser must support the Secure Sockets Layer (SSL)
            protocol. SSL provides a secure channel to send and receive data over the Internet. Microsoft Internet
            Explorer 6 or equivalent browser and above supports this feature. You will also need either a printer
            connected to your computer to print disclosures/notices or sufficient hard drive space available to save
            the information (e.g., 1 megabyte or more). You must have your own Internet service provider. We may
            amend (add to, delete or change) the terms of this Consent to electronic communication by providing you
            with advance notice.
          </v-card-subtitle>
        </v-card>
        <v-list
          flat
          subheader
          one-line
        >
          <v-list-item>
            <template>
              <v-list-item-action>
                <v-checkbox v-model="emailSettings"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>I agree</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
    <v-card-actions class="p-t-36">
      <v-btn
        large
        color="primary"
        width="240"
        class="m-0 f-s-18"
        @click="next"
        :disabled="!(emailSettings && textSettings)"
      >NEXT
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DataType } from '../../js/core'
import { OriginationApi } from '../../api/application'

export default {
  components: {},
  data () {
    return {
      page: DataType.PAGES.COMMUNICATION,
      disabled: true,
      showModal: false,
      textSettings: undefined,
      emailSettings: undefined,
      messageResult: {
        textMessage: '',
        emailMessage: ''
      },
      slides: [],
      customerOptIn: {
        homePhone: 24,
        email: 24
      },
      portfolioEnum: DataType.PortfolioEnum,
      scrollInvoked: 0,
      portfolio: {},
      portfolioId: '',
      personal: {},
      select: false,
      tags: 0
    }
  },
  methods: {
    text () {
      this.emailSettings = false
      this.select = false
    },
    onScroll (e) {
      if (e.target.scrollHeight - e.target.scrollTop < 600) {
        this.select = true
      }
    },
    samePortfolio () {
      const _this = this
      const portfolioArr = DataType.SAME_PORTFOLIO
      for (const number of portfolioArr) {
        if ((_this.tags & number) === number) {
          return true
        }
      }
      return false
    },
    next () {
      const _this = this
      this.$store.commit('setOverlay', true)
      OriginationApi.updateCustomerOptIn({
        features: ['OPT_IN'],
        data: { optIn: this.customerOptIn },
        customerId: localStorage.getItem(DataType.COOKIE_KEY.CUSTOMER_ID),
        portfolioId: localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      }, function (data) {
        const stepMessage = {
          currect: DataType.PAGES.COMMUNICATIION,
          next: DataType.PAGES.PREVIEW
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        const category = localStorage.getItem(DataType.COOKIE_KEY.CATEGORY)
        if (_this.samePortfolio() && parseInt(category) === DataType.CategoryEnum.RETURNING_CUSTOMER.value) {
          OriginationApi.generatePaymentPlanByLoanId({
            loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            portfolioId: localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          }, function () {
            OriginationApi.enteredAndLeft(
              localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
              DataType.PAGES.COMMUNICATION.step,
              DataType.PAGES.SIGNATURE.step,
              localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
            )
            _this.$router.push(DataType.PAGES.SIGNATURE.addressPC)
          })
        } else {
          OriginationApi.enteredAndLeft(
            localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            DataType.PAGES.COMMUNICATION.step,
            DataType.PAGES.PREVIEW.step,
            localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          )
          _this.$router.push(DataType.PAGES[_this.page.next].addressPC)
        }
      })
    },
    formatPhone (phone) {
      if (phone === null || phone === undefined) {
        return ''
      } else {
        const a = phone.substring(0, 3)
        const b = phone.substring(3, 6)
        const c = phone.substring(6, phone.length)
        return '(' + a + ') ' + b + '-' + c
      }
    }
  },
  created () {
    this.$store.commit('setCurrentPage', this.page)
    this.$store.commit('setOverlay', false)
    this.portfolioId = localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
    this.portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    this.personal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    OriginationApi.getCustomerOptIn({
      customerId: localStorage.getItem(DataType.COOKIE_KEY.CUSTOMER_ID),
      features: 'OPT_IN',
      portfolioId: localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
    })
  }
}
</script>
